import { Component } from "react";
import styled from "styled-components";

// Utils
import { rem, Font, Color, responsive, Opacity } from "../../utils/style";

// Components
import LoginForm from "./LoginForm";
import MagicLink from "../MagicLink";
import Text from "../Text";
import ThirdPartyAuth, { SIGN_IN } from "./ThirdPartyAuth";
import intl from "../../services/intl";
import { capitalizeFirstLetter } from "../../utils/string";

// Styled Elements
const Wrapper = styled.div.attrs({
  className: "d-flex flex-column",
})``;

const LoginSeparator = styled.div`
  height: 20px;
  color: ${Color.lessFadedBlue};
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  line-height: 20px;
  margin: 16px 0;
`;

const LoginHeading = styled.div`
  margin-bottom: 32px;
  text-align: center;

  ${responsive.md`
    margin-bottom: 40px;
  `}
  h1 {
    margin: 0;
    font-weight: 500;
    letter-spacing: 0px;
    margin-bottom: 8px;
    text-align: center;

    font-size: ${rem(18)};
    line-height: ${rem(28)};

    ${responsive.md`
      font-size: ${rem(20)};
      line-height: ${rem(30)};
    `}
  }

  p {
    ${Font.dutch};
    font-size: ${rem(16)};
    letter-spacing: 0px;
    line-height: ${rem(26)};
    font-weight: normal;
    margin-bottom: 0;

    ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
  `}
  }
`;

const CreateAccount = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;

  ${Font.dutch};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  letter-spacing: 0px;
  text-align: center;
  margin-top: 24px;

  ${responsive.md`
    margin-top: 40px;
  `}
  p {
    margin-bottom: 0;

    ${responsive.md`
      font-size: ${rem(18)};
      line-height: ${rem(28)};

      // displays on single line for desktop
      br {
        display: none;
      }
      a {
        margin-left: 5px;
      }
    `}
  }
`;

const CreateAccountLink = styled(MagicLink)`
  border-bottom: 1px solid ${Color.ritualBlue};
  line-height: 20px;
  transition: opacity 0.2s;

  &:hover {
    opacity: ${Opacity.light};
  }
`;

export default class LoginElements extends Component {
  render() {
    const { redirect } = this.props;

    return (
      <Wrapper>
        <LoginHeading>
          <h1>
            <Text id="authenticate.sign-in.heading" defaultMessage="Sign In" />
          </h1>
          <p>
            <Text
              id="authenticate.login.heading-p"
              defaultMessage="We’ll pick up right where we left off."
            />
          </p>
        </LoginHeading>

        <LoginForm redirect={redirect} />
        <LoginSeparator>
          <Text id={"authenticate.or"} defaultMessage={"or"} />
        </LoginSeparator>
        <ThirdPartyAuth buttonType={SIGN_IN} redirect={redirect} />

        <CreateAccount>
          <p>
            <Text
              id="authenticate.login.new-to-ritual"
              defaultMessage="New to Ritual?"
            />
            <br />
            <CreateAccountLink to="/signup" state={{ redirect }}>
              {capitalizeFirstLetter(
                intl.t("authenticate.login.sign-up", "Sign up"),
              )}
            </CreateAccountLink>
            .
          </p>
        </CreateAccount>
      </Wrapper>
    );
  }
}
